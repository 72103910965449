.discblock{
    height: 78vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 50px;
}
.messages{
    background-color: white;
    padding: 25px;
    overflow: auto;
}
.form{
    background-color: white;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    padding: 0 5px;
    align-items: center;
}
.form .ant-input-group-addon{
    background-color: #3f9dff!important;
}