.LoginLayout{
    /*background-color: #ffffff;*/
    background-image: url("/public/loginbackground.jpg");
    background-size: cover;
    background-position: center bottom;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.formBlock{
    background-color: rgba(245, 245, 245, 0.5);
    width: 400px;
    padding: 50px;
    border-radius: 20px;
    margin: 30px;
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
}
