h4{
  margin: 5px 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  background-color: #0039ff;
  border-radius: 100px;
}
fieldset{
  padding: 15px;
  margin-bottom:15px;
  border-radius: 10px;
  border: 1px solid silver;

}

legend{
  border: 1px solid silver!important;
  padding: 2px 15px!important;
  border-radius: 25px;
  width: auto!important;
  margin-bottom: 0px!important;
  color: black;
  font-size: 14px;
}

.trigger{
  font-size: 22px;
  color: #0c2240;
}
.bottomnav{
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bottomnav a{
  background-color: #3f9dff;
  color: white;
  padding: 12px 15px;
  border-radius: 10px;
}

.bottomnav .active{
  background-color: #0c2240;
}
.ant-card-actions button{
  width: 100%;
}

.ant-modal-footer{
  display: flex;
  gap: 10px;
  justify-content: end;
  align-items: center;
}